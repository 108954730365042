var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "15",
        height: "16",
        viewBox: "0 0 15 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10.316 8.33894L14.306 4.34993C14.6703 3.97272 14.8719 3.46758 14.8673 2.94319C14.8628 2.4188 14.6524 1.91716 14.2816 1.54634C13.9108 1.17552 13.4092 0.965204 12.8848 0.960647C12.3604 0.95609 11.8552 1.15761 11.478 1.52193L7.48799 5.51094L3.49797 1.52193C3.31348 1.33091 3.09281 1.17862 2.8488 1.07381C2.60479 0.968988 2.34236 0.91376 2.0768 0.911453C1.81124 0.909145 1.54787 0.959694 1.30208 1.06026C1.05628 1.16082 0.832972 1.30936 0.645187 1.49715C0.457401 1.68493 0.308889 1.90821 0.208328 2.15401C0.107766 2.3998 0.0571856 2.66323 0.0594933 2.92879C0.0618009 3.19435 0.116968 3.45675 0.221786 3.70076C0.326604 3.94477 0.478977 4.16544 0.669997 4.34993L4.65999 8.33894L0.669997 12.3289C0.478977 12.5134 0.326604 12.7341 0.221786 12.9781C0.116968 13.2221 0.0618009 13.4846 0.0594933 13.7502C0.0571856 14.0158 0.107766 14.2791 0.208328 14.5249C0.308889 14.7706 0.457401 14.9939 0.645187 15.1817C0.832972 15.3695 1.05628 15.518 1.30208 15.6186C1.54787 15.7192 1.81124 15.7697 2.0768 15.7674C2.34236 15.7651 2.60479 15.71 2.8488 15.6052C3.09281 15.5004 3.31348 15.348 3.49797 15.1569L7.48799 11.1669L11.478 15.1569C11.8552 15.5213 12.3604 15.7229 12.8848 15.7183C13.4092 15.7138 13.9108 15.5035 14.2816 15.1326C14.6524 14.7618 14.8628 14.2602 14.8673 13.7358C14.8719 13.2114 14.6703 12.7061 14.306 12.3289L10.316 8.33894Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }