var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "btn-chevron-right" }, [
    _c("span", [_vm._v(_vm._s(_vm.text))]),
    _c(
      "svg",
      {
        attrs: {
          width: "8",
          height: "14",
          viewBox: "0 0 8 14",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      [
        _c("path", {
          attrs: {
            d: "M1.30037 13.5C1.00037 13.5 0.800293 13.4 0.600293 13.2C0.200293 12.8 0.200293 12.2 0.600293 11.8L5.50032 6.8L0.600293 1.90004C0.200293 1.50004 0.200293 0.900012 0.600293 0.500012C1.00029 0.100012 1.60032 0.100012 2.00032 0.500012L7.70027 6.20002C8.10027 6.60002 8.10027 7.19999 7.70027 7.59999L2.00032 13.3C1.90032 13.4 1.60037 13.5 1.30037 13.5Z",
            fill: "#0071BC",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }