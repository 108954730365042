var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "property-important-info" },
    [
      _vm._l(_vm.specialDetails, function (value, index) {
        return _c("ul", { key: "property-specialDetails-" + index }, [
          _c("li", {
            staticClass: "property-detail-content",
            domProps: { innerHTML: _vm._s(value) },
          }),
        ])
      }),
      _c("br"),
      _c("p", [
        _vm._v(
          "\n        In caso di problemi al checkin o durante il soggiorno ti preghiamo di chiedere \n        immediatamente supporto alla reception/gestore della struttura e se necessario \n        all'assistenza Salabam all'indirizzo email " +
            _vm._s(_vm.emailSupport) +
            " \n        o al numero di telefono " +
            _vm._s(_vm.phoneNumber) +
            ". \n        Non potremo fornirti alcun supporto se decidessi di non soggiornare \n        nella struttura prenotata o dovessi prenotare un'altra \n        soluzione autonomamente.\n    "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }