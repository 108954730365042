var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "property__rates" },
    [
      _c("SvgOk"),
      _c("span", { staticClass: "property__scoredesc" }, [
        _vm._v(_vm._s(_vm.score_description)),
      ]),
      _c("span", { staticClass: "score" }, [
        _vm._v(_vm._s(Number((_vm.salabamScore / 20).toFixed(1))) + "/5"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }