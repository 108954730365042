var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "property__prices " + _vm.mixinGetIntegrationInfo(_vm.price).class,
      attrs: { id: "property-prices" },
    },
    [
      _vm.mixinGetIntegrationInfo().mode == "booking"
        ? _c(
            "div",
            [
              _c("PropertyModalNonRefundableDisclaimer", {
                attrs: {
                  modalId: "nonRefundableDisclaimerModal" + _vm.uniqueId,
                },
                on: {
                  "modal-ok": function ($event) {
                    _vm.trackNonRefundableModalClose("ok")
                    _vm.$emit("room-selected", {})
                  },
                  "modal-ko": function ($event) {
                    _vm.$bvModal.hide(
                      "nonRefundableDisclaimerModal" + _vm.uniqueId
                    )
                    _vm.trackNonRefundableModalClose("ko")
                  },
                },
              }),
              _c(
                "b-button",
                {
                  staticClass: "btn-with-icon",
                  attrs: {
                    variant: "secondary",
                    disabled: _vm.disabledButton,
                    "data-e2e": "goToPropertyButton",
                  },
                  on: {
                    click: function ($event) {
                      /*$emit('room-selected',{})*/
                      !_vm.refundable && _vm.mixinShowNonRefundableModal()
                        ? _vm.$bvModal.show(
                            "nonRefundableDisclaimerModal" + _vm.uniqueId
                          )
                        : _vm.$emit("room-selected", {})
                    },
                  },
                },
                [
                  _vm._v("\n            " + _vm._s(_vm.btnText) + " "),
                  _c("SvgChevronRight"),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.mixinGetIntegrationInfo().mode == "boxset"
        ? _c("div", { staticClass: "boxsetPurchase" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "resumeSmall" },
              [
                _c("BoxsetResume", {
                  attrs: {
                    focus: _vm.focus,
                    band: _vm.activeBand,
                    nights: _vm.nights,
                    price: _vm.price,
                    bookingHidePrice: _vm.$config.guiSettings.bookingHidePrice,
                  },
                }),
              ],
              1
            ),
            _vm.mixinGetIntegrationInfo().sso
              ? _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "selectBoxsetButton",
                        attrs: {
                          variant: "secondary",
                          disabled:
                            !(
                              _vm.mixinGetIntegrationInfo().integration
                                .integration == "jointly"
                            ) && //non devo essere jointly
                            !(
                              _vm.mixinGetIntegrationInfo().sso &&
                              _vm.mixinGetIntegrationInfo().welfare == false
                            ) && //non devo essere sso con welfare false
                            (_vm.disabledButton ||
                              (_vm.mixinGetIntegrationInfo().integration
                                .availability >= 0 &&
                                _vm.mixinGetIntegrationInfo().integration
                                  .availability < _vm.price)),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.show(
                              "selectBoxsetModal" + _vm.uniqueId
                            )
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("SvgBoxsetIcon"),
                            _vm._v(" Scegli\n                "),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.mixinGetIntegrationInfo().integration.availability >
                      -1 &&
                    _vm.mixinGetIntegrationInfo().integration.availability <
                      _vm.price
                      ? _c("CreditInfo")
                      : _vm._e(),
                    _c(
                      "b-modal",
                      {
                        staticClass: "modal-prices",
                        attrs: {
                          id: "selectBoxsetModal" + _vm.uniqueId,
                          size: "lg",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "modal-title",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n                    Stai acquistando il voucher "
                                  ),
                                  _c("span", { staticClass: "capitalize" }, [
                                    _vm._v(_vm._s(_vm.btnText)),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "modal-footer",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "modal-prices-footer" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "ml-1 mr-1",
                                          attrs: {
                                            variant: "secondary",
                                            "data-e2e": "selectRoomButton",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "room-selected",
                                                {}
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Ho capito")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3280638279
                        ),
                      },
                      [
                        _c("div", { staticClass: "modal-section" }, [
                          _c("p", [
                            _c("strong", [
                              _vm._v("Non stai prenotando una camera"),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v(
                              "\n                        Una volta acquistato il voucher riceverai dopo pochi istanti un'email con le istruzioni per fare la prenotazione.\n                    "
                            ),
                          ]),
                          _c(
                            "p",
                            [
                              _c("CopyScadenza", {
                                attrs: {
                                  default:
                                    "Il voucher è valido {value} mesi dalla data di acquisto.",
                                  promoMonths:
                                    "Promozione '{promoTitle}', se acquisti entro il {promoValidUntil} il voucher sarà valido {value} mesi (invece dei tradizionali {defaultMonths} mesi)",
                                  promoFixed:
                                    "Promozione '{promoTitle}', se acquisti entro il {promoValidUntil} il voucher sarà valido fino al {value} (invece dei tradizionali {defaultMonths} mesi)",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("br"),
                          _c("p", [
                            _c("strong", [_vm._v("Stai acquistando:")]),
                          ]),
                          _c(
                            "div",
                            [
                              _c("BoxsetResume", {
                                attrs: {
                                  focus: _vm.focus,
                                  band: _vm.activeBand,
                                  nights: _vm.nights,
                                  price: _vm.price,
                                  bookingHidePrice:
                                    this.$config.guiSettings.bookingHidePrice,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "selectBoxsetButton",
                        attrs: {
                          variant: "secondary",
                          disabled: _vm.disabledButton,
                          "data-e2e": "selectRoomButton",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("room-selected", {})
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("SvgBoxsetIcon"),
                            _vm._v(" Scegli\n                "),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "selectBoxsetInfo" }, [
      _c("strong", [
        _vm._v("Per prenotare questa soluzione"),
        _c("br"),
        _vm._v("acquista il voucher:"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }