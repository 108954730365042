<template>
    <div class="property__rates">
        <SvgOk />
        <span class="property__scoredesc">{{score_description}}</span>
        <span class="score">{{Number((salabamScore/20).toFixed(1))}}/5</span>
        <!--
        <span class="property__scorestars">
            <Stars :hotelRating="parseFloat(hotelRating)" />
        </span>
        -->
    </div>
</template>

<script>
    import SvgOk from '../../svg/ok'
    //import Stars from '../../atoms/stars'

    export default {
        name: 'property-rates',
        components:
        {
          SvgOk,
          //Stars
        },
        props:
        {
          score_description: String,
          salabamScore: String
        }
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .property__rates
    {
        margin:16px auto;
        margin-bottom:0;
        font-size:15px;
        font-weight:500;
        align-items: center;
        $icon-width:70px;
        position:relative;
        padding-left:22px;
        &>svg
        {
            //width:10px;
            position:absolute;
            left:-6px;
            top:3px;
        }
        .property__scoreicon
        {
            width:$icon-width;
            transform:translateY(1px);
            color:$white;
            text-align:center;
            svg
            {
                width:100%;
            }
            .score
            {
                position:absolute;
                left:0;
                right:0;
                text-align:center;
                top:50%;
                transform:translateY(-50%);
            }
        }
        /*
        .property__scorestars
        {
            position:relative;
            text-align:center;
            margin-left:1rem;
            display:inline-block;
            .stars
            {
                transform:translateY(-2px);
            }
        }
        */
        
        @include media-breakpoint-up(lg)
        { 
        }
    }
</style>