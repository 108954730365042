<template>
    <div id="property-prices" :class="'property__prices '+mixinGetIntegrationInfo(price).class">

        <div v-if="mixinGetIntegrationInfo().mode == 'booking'">
            <PropertyModalNonRefundableDisclaimer
                :modalId="'nonRefundableDisclaimerModal'+uniqueId"
                v-on:modal-ok="trackNonRefundableModalClose('ok'); $emit('room-selected',{})"
                v-on:modal-ko="$bvModal.hide('nonRefundableDisclaimerModal'+uniqueId); trackNonRefundableModalClose('ko');"
            />
            <b-button
                class="btn-with-icon"
                variant="secondary"
                :disabled="disabledButton"
                data-e2e="goToPropertyButton"
                @click="
                    /*$emit('room-selected',{})*/
                    (!refundable && mixinShowNonRefundableModal()) ? $bvModal.show('nonRefundableDisclaimerModal'+uniqueId) : $emit('room-selected',{})
                "
            >
                {{btnText}} <SvgChevronRight />
            </b-button>
        </div>

        <div v-if="mixinGetIntegrationInfo().mode == 'boxset'" class="boxsetPurchase">
            <p class="selectBoxsetInfo">
                <strong>Per prenotare questa soluzione<br>acquista il voucher:</strong>
            </p>

            <div class="resumeSmall">
            <BoxsetResume
                :focus="focus"
                :band="activeBand"
                :nights="nights"
                :price="price"
                :bookingHidePrice="$config.guiSettings.bookingHidePrice"
            />
            </div>
            
            <!-- sso => modale -->
            <div v-if="mixinGetIntegrationInfo().sso">
                <b-button class="selectBoxsetButton" variant="secondary"
                    :disabled="
                        !(mixinGetIntegrationInfo().integration.integration == 'jointly') && //non devo essere jointly
                        !(mixinGetIntegrationInfo().sso && mixinGetIntegrationInfo().welfare == false) && //non devo essere sso con welfare false                        
                        (disabledButton || (mixinGetIntegrationInfo().integration.availability >= 0 && mixinGetIntegrationInfo().integration.availability < price))
                    "
                    @click="$bvModal.show('selectBoxsetModal'+uniqueId)"
                >
                    <span>
                        <SvgBoxsetIcon /> Scegli
                    </span>
                </b-button>

                <CreditInfo v-if="mixinGetIntegrationInfo().integration.availability > -1 && mixinGetIntegrationInfo().integration.availability < price" />

                <b-modal :id="'selectBoxsetModal'+uniqueId" size="lg" class="modal-prices">
                    <template v-slot:modal-title>
                        Stai acquistando il voucher <span class="capitalize">{{btnText}}</span>
                    </template>
                    <div class="modal-section">
                        <p>
                            <strong>Non stai prenotando una camera</strong>
                        </p>
                        <p>
                            Una volta acquistato il voucher riceverai dopo pochi istanti un'email con le istruzioni per fare la prenotazione.
                        </p>
                        <p>
                            <CopyScadenza
                                default="Il voucher è valido {value} mesi dalla data di acquisto."
                                promoMonths="Promozione '{promoTitle}', se acquisti entro il {promoValidUntil} il voucher sarà valido {value} mesi (invece dei tradizionali {defaultMonths} mesi)"
                                promoFixed="Promozione '{promoTitle}', se acquisti entro il {promoValidUntil} il voucher sarà valido fino al {value} (invece dei tradizionali {defaultMonths} mesi)"
                            />
                        </p>

                        <br>

                        <p>
                            <strong>Stai acquistando:</strong>
                        </p>

                        <div>
                            <BoxsetResume
                                :focus="focus"
                                :band="activeBand"
                                :nights="nights"
                                :price="price"
                                :bookingHidePrice="(this.$config.guiSettings.bookingHidePrice)"
                            />
                        </div>
                    </div>
                    <template v-slot:modal-footer>
                        <div class="modal-prices-footer">
                            <b-button class="ml-1 mr-1" variant="secondary" @click="$emit('room-selected',{})" data-e2e="selectRoomButton">Ho capito</b-button>
                        </div>
                    </template>
                </b-modal>
            </div>
            <!-- fine sso -->

            <!-- no sso => select room -->
            <div v-else>

                <b-button
                    class="selectBoxsetButton"
                    variant="secondary"
                    :disabled="disabledButton"
                    data-e2e="selectRoomButton"
                    @click="$emit('room-selected',{})"
                >
                    <span>
                        <SvgBoxsetIcon /> Scegli
                    </span>
                </b-button>
            </div>
            <!-- fine no sso -->

        </div>
    </div>
</template>

<script>
    import BoxsetResume from '../../blocks/boxset/boxset-resume'
    import CreditInfo from '@/components/blocks/common/credit-info'
    import PropertyModalNonRefundableDisclaimer from '@/components/blocks/property/property-modal-non-refundable-disclaimer'
    //import SvgInfoCircle from '../../svg/info-circle'
    import SvgBoxsetIcon from '../../svg/boxset-icon'
    import SvgChevronRight from '../../svg/chevron-right'
    import CopyScadenza from '@/components/blocks/common/copy-scadenza'

    export default {
        name: 'property-prices',
        components:
        {
            BoxsetResume,
            CreditInfo,
            PropertyModalNonRefundableDisclaimer,
            //SvgInfoCircle,
            SvgBoxsetIcon,
            SvgChevronRight,
            CopyScadenza,
        },
        props:
        {
            price: Number,
            btnText: String,
            additionalInfo: Object,
            disableUnavailable: Boolean,
            uniqueId: String,
            refundable: Boolean,
        },
        computed: {
            activeBand: {
                get() { return this.$store.state.activeBand },
                set() {}
            },            
            nights: {
                get() { return this.$store.state.dates.nights },
                set() {}
            },
            focus: {
                get() { return this.$store.state.focus },
                set() {}
            },
            availability: {
                get() { return this.$store.state.integration.availability },
                set() {}
            },
            occupancy: {
                get() { return this.$store.state.occupancy },
                set(value) { this.mixinSendMutation('setOccupancy',value) }
            },
            dates: {
                get() { return this.$store.state.dates },
                set(value) { this.mixinSendMutation('setDates',value) }
            },
            disabledButton: {
                get() { return ((this.$route.name !== 'search') && !this.mixinGetIntegrationInfo(this.price).canCheckout) },
                set(){}
            },
        },
        methods:
        {
            /*selectRoom()
            {

            },*/
            trackNonRefundableModalClose(label){
              // label: ok / ko
              if(process.env.VUE_APP_MODE != 'production') window.console.log('trackNonRefundableModal: closed ' + label);

              if(this.$gtm) this.$gtm.trackEvent({
                event: 'NonRefundableModal',
                category: 'userBehaviour',
                action: 'closed',
                label: label,
                value: 0,
              });

            },
        },
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    #property-prices
    {
        display: flex;
        //flex-direction:column;
        flex-wrap:wrap;
        align-items: center;
        text-align:center;
        .property__resume {width:70%;}
        //.property__prices-occupancy{width:30%;}
        .creditKoCopy
        {
            display:block;
            font-size: 11px;
            margin-top:1rem;
            margin-bottom:0;
            svg
            {
                width:14px;
            }
        }
        .boxsetPurchase
        {
            width:100%;
            text-align:center;
        }
        .btn-with-icon
        {
            display:block;
            width:100%;
            max-width: 160px;
            margin:auto;
            margin-top:1rem;
            svg path
            {
                stroke:$white;
            }
        }
        .resumeSmall
        {
            margin-top:1.5rem;
            margin-bottom:1rem;
            *
            {
                font-size:13px;
                margin-right:6px;
            }
            .salaBadge
            {
                padding:0.15rem 0.75rem;
            }
            svg
            {
                width:20px;
                height:20px;
            }
            .boxsetResume
            {
                justify-content:center;
            }
        }
        hr
        {
            display:none;
        }
        button
        {
            width:100%;
        }
        
        .selectBoxsetButton
        {
            text-transform: capitalize;
            svg
            {
                width:18px;
                height:18px;
                margin-right:4px;
                margin-left:0;
                *
                {
                    fill:$white;
                }
            }
        }
        .selectBoxsetInfo
        {
            //font-weight: bold;
            margin-top:0.75rem;
            margin-bottom:0;
            strong
            {
                display:block;
                margin-bottom:0.35rem;
            }
        }
        @include media-breakpoint-up(md)
        {
            flex-direction:column;
            button
            {
                width:auto;
            }
            hr
            {
                display:block;
            }
            //.property__prices-occupancy,
            .property__resume
            {
                width:100%;
            }
        }
        .property__resume
        {
            display:flex;
            flex-direction: column;
        }
        /*
        .property__prices-occupancy
        {
            color:rgba($smooth-black,0.5);
            font-size:12px;
            display:flex;
            flex-direction:column;
        }
        */
        .property__prices-total
        {
            color:$secondary;
            font-size:22px;
            font-weight:600;
            @include media-breakpoint-up(xl)
            {
                font-size:30px;
            }
        }
        .property__prices-per-night
        {
            color:$black;
            font-size:12px;
            font-weight:400;
            @include media-breakpoint-up(lg)
            {
                //margin-bottom: 1rem;
            }
        }
        .property__prices-alert
        {
            color:$danger;
            font-size:12px;
            font-weight:400;
            font-style:italic;
            svg
            {
                width:14px;
                margin-left:3px;
                * {
                    fill:$danger;
                }
            }
            @include media-breakpoint-up(lg)
            {
                //margin-bottom: 1rem;
            }
        }
        hr
        {
            border-top:1px solid $gray-300;
            //display:block;
            width:80%;
            margin:0.75rem auto;
        }
        button
        {
            font-size: 15px;
            margin-top: 1rem;
            font-weight: 600;
            padding:10px;
            font-weight:400;
            width:100%;
            svg
            {
                margin-left:8px;
                transform:translateY(-2px);
            }
        }

        .danger
        {
            color:$danger;
        }
    }    

    //flagWelfare
    .gold .rate__right
    {
        .property__prices-total,
        //.property__prices-per-night,
        .property__prices-alert
        {
            color:$gold !important;
        }
        .property__prices-alert svg *
        {
            fill:$gold !important;
        }
    }
    .red .rate__right
    {
        .property__prices-total,
        //.property__prices-per-night,
        .property__prices-alert
        {
            color:$danger !important;
        }
        .property__prices-alert svg *
        {
            fill:$danger !important;
        }
    }    
    .default .rate__right //.blue
    {
        .property__prices-total,
        //.property__prices-per-night,
        .property__prices-alert
        {
            color:$secondary !important;
        }
        .property__prices-alert svg *
        {
            fill:$secondary !important;
        }
    }   
        .modal-prices-footer
        {
            display:flex;
            min-width:100%;
            text-align:center;
            justify-content: center;
            align-items: center;
        }

</style>