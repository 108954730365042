var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "panel-white rate default" }, [
    _c("div", { staticClass: "rate__left" }, [
      _c("h5", { staticClass: "rate__title" }, [
        _vm._v("Soluzione " + _vm._s(_vm.rateIndex + 1)),
      ]),
      _c("hr"),
      _c("div", { staticClass: "d-flex" }, [
        _vm.rate.refundable != true
          ? _c(
              "span",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value:
                      "La prenotazione non potrà essere cancellata nè modificata. Prenota quando sei sicuro del tuo viaggio",
                    expression:
                      "'La prenotazione non potrà essere cancellata nè modificata. Prenota quando sei sicuro del tuo viaggio'",
                    modifiers: { hover: true, top: true },
                  },
                ],
                staticClass: "amenity fail",
              },
              [
                _c("div", [_c("SvgInfoCircle")], 1),
                _vm._v(
                  "\n                Soluzione non rimborsabile\n            "
                ),
              ]
            )
          : _c(
              "span",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value:
                      "Potrai cancellare gratuitamente la prenotazione entro il " +
                      _vm.rate.refundableBefore +
                      " e il tuo voucher Salabam sarà riattivato per una nuova prenotazione",
                    expression:
                      "'Potrai cancellare gratuitamente la prenotazione entro il ' + rate.refundableBefore + ' e il tuo voucher Salabam sarà riattivato per una nuova prenotazione'",
                    modifiers: { hover: true, top: true },
                  },
                ],
                staticClass: "amenity icon-refundable mb-0",
              },
              [_vm._v(" Cancellazione gratuita")]
            ),
        _c("div", { staticClass: "property__prices-occupancy" }, [
          _c("span", { staticClass: "nowrap" }, [
            _vm._v("\n                    " + _vm._s(_vm.dates.nights) + " "),
            _vm.dates.nights == 1
              ? _c("span", [_vm._v("Notte")])
              : _c("span", [_vm._v("Notti")]),
          ]),
          _c("span", { staticClass: "nowrap" }, [
            _vm._v(
              "\n                    " + _vm._s(_vm.occupancy.adults) + " "
            ),
            _vm.occupancy.adults == 1
              ? _c("span", [_vm._v("Adulto")])
              : _c("span", [_vm._v("Adulti")]),
            _vm.occupancy.children.length
              ? _c("span", [
                  _vm._v(
                    "\n                    -\n                    " +
                      _vm._s(_vm.occupancy.children.length) +
                      " "
                  ),
                  _vm.occupancy.children.length == 1
                    ? _c("span", [_vm._v("Bambino")])
                    : _c("span", [_vm._v("Bambini")]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _c("hr"),
      Object.values(_vm.rate.amenities).length
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "property__amenities" },
                [
                  _vm._l(
                    Object.values(_vm.rate.amenitiesEps).reverse().slice(0, 2),
                    function (amenity) {
                      return _c(
                        "span",
                        {
                          key: amenity.id,
                          class:
                            "amenity " +
                            (_vm.icons["propertyAmenities"][amenity.amenity] !==
                            undefined
                              ? _vm.icons["propertyAmenities"][amenity.amenity]
                              : "icon-default"),
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(amenity.name) +
                              "\n               "
                          ),
                        ]
                      )
                    }
                  ),
                  Object.values(_vm.rate.amenitiesEps).length > 2
                    ? _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.show(
                                "modal-room" +
                                  _vm.roomId +
                                  "-rate-" +
                                  _vm.rate.id +
                                  "-services"
                              )
                            },
                          },
                        },
                        [
                          _c("BtnChevronRight", {
                            attrs: { text: "Tutti i servizi" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    id:
                      "modal-room" +
                      _vm.roomId +
                      "-rate-" +
                      _vm.rate.id +
                      "-services",
                    size: "lg",
                    "hide-footer": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "modal-title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Servizi inclusi nella tariffa\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3110118865
                  ),
                },
                [
                  _c("div", { staticClass: "modal-section" }, [
                    _c(
                      "div",
                      { staticClass: "property__amenities" },
                      _vm._l(
                        Object.values(_vm.rate.amenitiesEps).reverse(),
                        function (amenity) {
                          return _c(
                            "span",
                            {
                              key: amenity.id,
                              class:
                                "amenity " +
                                (_vm.icons["propertyAmenities"][
                                  amenity.amenity
                                ] !== undefined
                                  ? _vm.icons["propertyAmenities"][
                                      amenity.amenity
                                    ]
                                  : "icon-default"),
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(amenity.name) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ]
              ),
              _c("hr", { staticClass: "mt-1" }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "bedAndFees" }, [
        Object.keys(this.rate.bedding).length == 1
          ? _c(
              "div",
              [
                _c(
                  "b-form-group",
                  _vm._l(_vm.rate.bedding, function (bed, index) {
                    return _c(
                      "b-form-radio",
                      {
                        key: index,
                        attrs: {
                          name: _vm.rate.id + "-bedding-radios",
                          value: index,
                        },
                        model: {
                          value: _vm.bedKey,
                          callback: function ($$v) {
                            _vm.bedKey = $$v
                          },
                          expression: "bedKey",
                        },
                      },
                      [_vm._v(_vm._s(bed.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.top",
                    value: "Richiesta non confermata soggetta a disponibilità",
                    expression:
                      "'Richiesta non confermata soggetta a disponibilità'",
                    modifiers: { hover: true, top: true },
                  },
                ],
              },
              [
                _c(
                  "b-form-group",
                  _vm._l(_vm.rate.bedding, function (bed, index) {
                    return _c(
                      "b-form-radio",
                      {
                        key: index,
                        attrs: {
                          name: _vm.rate.id + "-bedding-radios",
                          value: index,
                        },
                        model: {
                          value: _vm.bedKey,
                          callback: function ($$v) {
                            _vm.bedKey = $$v
                          },
                          expression: "bedKey",
                        },
                      },
                      [_vm._v(_vm._s(bed.label))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
        _vm.fees && _vm.mixinGetIntegrationInfo().mode == "boxset"
          ? _c(
              "div",
              {
                staticClass: "feesBox",
                attrs: {
                  id: "popover-target-fees-" + _vm.roomId + "-" + _vm.rateIndex,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "fees" },
                  [
                    _c(
                      "span",
                      [_c("SvgInfoCircle"), _vm._v(" Da pagare in struttura:")],
                      1
                    ),
                    _c("span", { staticClass: "price" }, [
                      _vm._v(_vm._s(_vm.fees) + "€"),
                    ]),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target:
                            "popover-target-fees-" +
                            _vm.roomId +
                            "-" +
                            _vm.rateIndex,
                          triggers: "hover",
                          placement: "top",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "Costi non inclusi nella tariffa e da pagare in struttura:"
                          ),
                        ]),
                        _c("br"),
                        _vm.rate.totals.mandatory_tax > 0
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-content-between" },
                              [
                                _c("span", { staticClass: "pr-2" }, [
                                  _vm._v("Tassa di soggiorno:"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      (_vm.rate.totals.mandatory_tax * 100) /
                                        100
                                    ) + "€"
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.rate.totals.mandatory_fee > 0
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-content-between" },
                              [
                                _c("span", { staticClass: "pr-2" }, [
                                  _vm._v("Spese di pulizia ed utenze:"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      (_vm.rate.totals.mandatory_fee * 100) /
                                        100
                                    ) + "€"
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.rate.totals.resort_fee > 0
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-content-between" },
                              [
                                _c("span", { staticClass: "pr-2" }, [
                                  _vm._v("Resort fee:"),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      (_vm.rate.totals.resort_fee * 100) / 100
                                    ) + "€"
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c("hr"),
    ]),
    _c("div", { staticClass: "rate__right" }, [
      _vm.mixinGetIntegrationInfo().mode == "booking"
        ? _c(
            "div",
            { staticClass: "room__prices" },
            [
              _c("PropertyPrices", {
                attrs: {
                  additionalInfo: {},
                  uniqueId: _vm.roomId + _vm.rateIndex,
                  disableUnavailable: true,
                  rateId: _vm.rate.id,
                  price: _vm.rate.public_price,
                  btnText: "Prenota",
                  refundable: _vm.rate.refundable,
                },
                on: {
                  "room-selected": function ($event) {
                    return _vm.$parent.$emit("room-selected", {
                      rateId: _vm.rate.id,
                      roomId: _vm.roomId,
                      bedKey: _vm.bedKey,
                    })
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.mixinGetIntegrationInfo().mode == "boxset"
        ? _c(
            "div",
            { staticClass: "room__prices" },
            [
              _c("PropertyPrices", {
                attrs: {
                  additionalInfo: {},
                  uniqueId: _vm.roomId + _vm.rateIndex,
                  disableUnavailable: true,
                  rateId: _vm.rate.id,
                  price: _vm.rate.public_price,
                  btnText:
                    _vm.focus +
                    " " +
                    _vm.activeBand.toUpperCase() +
                    " " +
                    _vm.nights +
                    (_vm.nights == 1 ? " notte" : " notti"),
                  refundable: _vm.rate.refundable,
                },
                on: {
                  "room-selected": function ($event) {
                    return _vm.$parent.$emit("room-selected", {
                      rateId: _vm.rate.id,
                      roomId: _vm.roomId,
                      bedKey: _vm.bedKey,
                    })
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.fees && _vm.mixinGetIntegrationInfo().mode == "booking"
        ? _c("div", { staticClass: "feesBox" }, [
            _c("br"),
            _c(
              "div",
              { staticClass: "fees" },
              [
                _c(
                  "span",
                  [
                    _c("SvgInfoCircle", {
                      attrs: {
                        id:
                          "popover-target-fees-" +
                          _vm.roomId +
                          "-" +
                          _vm.rateIndex,
                      },
                    }),
                    _vm._v(" Da pagare in struttura:"),
                  ],
                  1
                ),
                _c("span", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.fees) + " €"),
                ]),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      target:
                        "popover-target-fees-" +
                        _vm.roomId +
                        "-" +
                        _vm.rateIndex,
                      triggers: "hover",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Costi non inclusi nella tariffa e da pagare in struttura:"
                      ),
                    ]),
                    _c("br"),
                    _vm.rate.totals.mandatory_tax > 0
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c("span", { staticClass: "pr-2" }, [
                              _vm._v("Tassa di soggiorno:"),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.rate.totals.mandatory_tax * 100) / 100
                                ) + "€"
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.rate.totals.mandatory_fee > 0
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c("span", { staticClass: "pr-2" }, [
                              _vm._v("Spese di pulizia ed utenze:"),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.rate.totals.mandatory_fee * 100) / 100
                                ) + "€"
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.rate.totals.resort_fee > 0
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c("span", { staticClass: "pr-2" }, [
                              _vm._v("Resort fee:"),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.rate.totals.resort_fee * 100) / 100
                                ) + "€"
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }