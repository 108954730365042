var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "propertyModalNonRefundableDisclaimer",
      attrs: { id: _vm.modalId, size: "lg" },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [_vm._v("\n        Prenotazione non rimborsabile\n    ")]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "modal-prices-footer" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1 mr-1",
                      attrs: { variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("modal-ko", {})
                        },
                      },
                    },
                    [_vm._v("Ok! Scelgo altro")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1 mr-1 btn-gray",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("modal-ok", {})
                        },
                      },
                    },
                    [_vm._v("Ho capito, vado avanti")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "modal-section" }, [
        _c("p", [
          _vm._v("\n        Questa prenotazione "),
          _c("b", [_vm._v("non dà diritto a rimborso")]),
          _vm._v(" in caso di cancellazione e non è modificabile.\n      "),
        ]),
        _c("p", [
          _vm._v(
            "\n        In caso di problemi a viaggiare per qualsiasi motivazione, anche legata al Covid, "
          ),
          _c("b", [
            _vm._v(
              "la possibilità di ottenere un rimborso è demandata alla volontà della struttura"
            ),
          ]),
          _vm._v(". Molte strutture adottano una linea restrittiva.\n      "),
        ]),
        _c("p", [
          _vm._v("\n        Ti consigliamo di scegliere "),
          _c("b", [_vm._v("soluzioni di tipo Rimborsabile")]),
          _vm._v(".\n      "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }