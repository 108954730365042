var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "room panel-gray" },
    [
      _vm.loading
        ? _c("Spinner")
        : _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "room__left",
                  attrs: { sm: "12", lg: "5", xl: "6" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "room__left-inner" },
                    [
                      _c("h5", { staticClass: "room__name" }, [
                        _vm._v(_vm._s(_vm.room.name)),
                      ]),
                      _vm.room.occupancy &&
                      _vm.room.occupancy.max_allowed &&
                      _vm.room.occupancy.max_allowed.total
                        ? _c(
                            "div",
                            { staticClass: "room__occupancy" },
                            [
                              _c("div", [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "21",
                                      height: "14",
                                      viewBox: "0 0 21 14",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M14.5999 7.19995C16.091 7.19995 17.2999 5.99117 17.2999 4.5C17.2999 3.00883 16.091 1.80005 14.5999 1.80005C13.1087 1.80005 11.8999 3.00883 11.8999 4.5C11.8999 5.99117 13.1087 7.19995 14.5999 7.19995Z",
                                        fill: "#333333",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "M20.6 12.6C20.4 9.99998 18.8 8 14.6 8C14 8 13.5 7.99998 13 8.09998C14.2 9.09998 15 10.6 15.1 12.4V12.5C15.1 12.7 15.1 12.8 15 13H20.1C20.4 13.1 20.6 12.9 20.6 12.6Z",
                                        fill: "#333333",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "M7.49963 6.5C9.10126 6.5 10.3997 5.2016 10.3997 3.59998C10.3997 1.99835 9.10126 0.699951 7.49963 0.699951C5.89801 0.699951 4.59961 1.99835 4.59961 3.59998C4.59961 5.2016 5.89801 6.5 7.49963 6.5Z",
                                        fill: "#333333",
                                      },
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "M0.899902 12.5C0.899902 12.8 1.0999 13 1.3999 13H13.5999C13.8999 13 14.0999 12.8 14.0999 12.5C13.8999 9.7 12.0999 7.5 7.49988 7.5C2.89988 7.5 1.0999 9.7 0.899902 12.5Z",
                                        fill: "#333333",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n                        Per " +
                                    _vm._s(
                                      _vm.room.occupancy.max_allowed.total
                                    ) +
                                    " "
                                ),
                                _vm.room.occupancy.max_allowed.total == 1
                                  ? _c("span", [_vm._v("persona")])
                                  : _c("span", [_vm._v("persone")]),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass: "room__modal-link",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.show(
                                        "modal-room-" +
                                          _vm.room.id +
                                          "-features"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("BtnChevronRight", {
                                    attrs: {
                                      text: "Caratteristiche della camera",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-modal",
                                {
                                  attrs: {
                                    id:
                                      "modal-room-" + _vm.room.id + "-features",
                                    size: "lg",
                                    "hide-footer": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "modal-title",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\n                            Caratteristiche della camera\n                        "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    873756269
                                  ),
                                },
                                [
                                  _c("div", { staticClass: "modal-section" }, [
                                    _vm.room.descriptions
                                      ? _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.room.descriptions.overview
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "property__amenities" },
                                      _vm._l(
                                        _vm.room.amenities,
                                        function (at, index) {
                                          return _c(
                                            "span",
                                            {
                                              key: index,
                                              class:
                                                "amenity " +
                                                (_vm.icons.amenities[at.id] !==
                                                undefined
                                                  ? _vm.icons.amenities[at.id]
                                                  : "icon-default"),
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(at.name) +
                                                  "\n                                "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "slick-container" },
                        [
                          _c(
                            "Slick",
                            {
                              ref: "roomSlider-" + _vm.room.id,
                              attrs: {
                                id: "roomSlider-" + _vm.room.id,
                                options: _vm.slickOptions,
                              },
                            },
                            _vm._l(
                              _vm.room.images.slice(0, 100),
                              function (thumb, thumbIndex) {
                                return _c("div", { key: thumbIndex }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "room__image",
                                      style:
                                        "background-image:url(" +
                                        thumb.src +
                                        ")",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleInternalModal(
                                            "roomGalleryFullwidth-" +
                                              _vm.room.id,
                                            true,
                                            thumbIndex
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "slick-caption" },
                                        [_vm._v(_vm._s(thumb.caption))]
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "slick-arrow slick-prev",
                              attrs: {
                                variant: "light",
                                id: "slick-prev-" + _vm.room.id,
                              },
                            },
                            [_c("SvgChevronLeft")],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "slick-arrow slick-next",
                              attrs: {
                                variant: "light",
                                id: "slick-next-" + _vm.room.id,
                              },
                            },
                            [_c("SvgChevronRight")],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-modal",
                        {
                          ref: "roomGalleryFullwidth-" + _vm.room.id,
                          attrs: {
                            id: "roomGalleryFullwidth-" + _vm.room.id,
                            "data-class": "internalGallery",
                            size: "xl",
                            "hide-header": "",
                            "hide-footer": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-block text-center slick-container",
                            },
                            [
                              _c(
                                "Slick",
                                {
                                  attrs: {
                                    options: {
                                      dots: true,
                                      fade: true,
                                      prevArrow:
                                        "#slick-prev-internal-" + _vm.room.id,
                                      nextArrow:
                                        "#slick-next-internal-" + _vm.room.id,
                                      asNavFor: "#roomSlider-" + _vm.room.id,
                                      initialSlide: _vm.firstSlideIndex,
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.room.images.slice(0, 100),
                                  function (thumb, thumbModalIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: thumbModalIndex,
                                        staticClass: "internalImage",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "slick-caption" },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(thumb.caption)),
                                            ]),
                                          ]
                                        ),
                                        _c("img", {
                                          attrs: { src: thumb.src },
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "slick-arrow slick-prev",
                                  attrs: {
                                    variant: "light",
                                    id: "slick-prev-internal-" + _vm.room.id,
                                  },
                                },
                                [_c("SvgChevronLeft")],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "slick-arrow slick-next",
                                  attrs: {
                                    variant: "light",
                                    id: "slick-next-internal-" + _vm.room.id,
                                  },
                                },
                                [_c("SvgChevronRight")],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "closeInternal",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleInternalModal(
                                    "roomGalleryFullwidth-" + _vm.room.id,
                                    false,
                                    _vm.thumbModalIndex
                                  )
                                },
                              },
                            },
                            [_c("SvgCross")],
                            1
                          ),
                        ]
                      ),
                      _vm.occupancy.adults + _vm.occupancy.children.length >
                      Object.values(_vm.room.rates[0].bedding)[0].paxCount
                        ? _c(
                            "div",
                            { staticClass: "room__bedding-alert" },
                            [
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.show(
                                        "modal-room-" +
                                          _vm.room.id +
                                          "-features"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-alert",
                                    {
                                      staticClass: "bedding-alert",
                                      attrs: { variant: "warning", show: "" },
                                    },
                                    [
                                      _c("SvgInfoCircle", {
                                        directives: [
                                          {
                                            name: "b-popover",
                                            rawName: "v-b-popover.hover.top",
                                            value:
                                              "Il numero di posti letto di questa camera potrebbe essere inferiore al numero di viaggiatori e potrebbe essere richiesto un supplemento",
                                            expression:
                                              "'Il numero di posti letto di questa camera potrebbe essere inferiore al numero di viaggiatori e potrebbe essere richiesto un supplemento'",
                                            modifiers: {
                                              hover: true,
                                              top: true,
                                            },
                                          },
                                        ],
                                      }),
                                      _vm._v(
                                        "\n                            Controlla il numero dei letti disponibili\n                        "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.show(
                                        "modal-room-" + _vm.room.id + "-details"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-alert",
                                    {
                                      staticClass: "bedding-alert",
                                      attrs: { variant: "warning", show: "" },
                                    },
                                    [
                                      _c("SvgInfoCircle", {
                                        directives: [
                                          {
                                            name: "b-popover",
                                            rawName: "v-b-popover.hover.top",
                                            value:
                                              "La struttura potrebbe richiedere un supplemento per eventuali letti aggiuntivi necessari. Contattaci ad " +
                                              _vm.site.emailSupport +
                                              " o scegli un'altra camera",
                                            expression:
                                              "'La struttura potrebbe richiedere un supplemento per eventuali letti aggiuntivi necessari. Contattaci ad '+site.emailSupport+' o scegli un\\'altra camera'",
                                            modifiers: {
                                              hover: true,
                                              top: true,
                                            },
                                          },
                                        ],
                                      }),
                                      _vm._v(
                                        "\n                            Verifica il costo per eventuali letti aggiuntivi\n                        "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-modal",
                                {
                                  attrs: {
                                    id:
                                      "modal-room-" + _vm.room.id + "-details",
                                    size: "lg",
                                    "hide-footer": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "modal-title",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\n                            Informazioni importanti\n                        "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3150339071
                                  ),
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "modal-section" },
                                    [
                                      _vm.propertyDetails.specialDetails
                                        ? _c("PropertyImportantInfo", {
                                            attrs: {
                                              specialDetails:
                                                _vm.propertyDetails
                                                  .specialDetails,
                                            },
                                          })
                                        : _vm._e(),
                                      _c("br"),
                                      _c("PropertyToKnow", {
                                        attrs: { details: _vm.propertyDetails },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "room__right",
                  attrs: { sm: "12", lg: "7", xl: "6" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "room__right-inner" },
                    _vm._l(_vm.room.rates, function (rate, index) {
                      return _c("RoomRate", {
                        key: index,
                        attrs: {
                          rate: rate,
                          rateIndex: index,
                          roomId: _vm.room.id,
                        },
                      })
                    }),
                    1
                  ),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }