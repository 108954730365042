var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "property-reviews", "data-size": _vm.size } },
    [
      _vm.reviews && _vm.reviews.length > 0
        ? _c("div", { staticClass: "reviewsWrapper" }, [
            _vm._m(0),
            _c("small", { staticClass: "reviewsSubitle" }, [
              _vm._v(
                "Estratto di " +
                  _vm._s(_vm.totalReviews) +
                  " recensioni verificate"
              ),
            ]),
            _c(
              "div",
              { staticClass: "slick-wrapper" },
              [
                _c(
                  "Slick",
                  {
                    ref: "slick-reviews",
                    attrs: { options: _vm.slickOptions },
                    on: {
                      afterChange: _vm.reviewsSlickHandleAfterChange,
                      swipe: _vm.reviewsSlickHandleSwipe,
                    },
                  },
                  _vm._l(_vm.reviews, function (value, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal",
                                value: "review-" + _vm.size + "-" + index,
                                expression: "'review-'+size+'-'+index",
                              },
                            ],
                            staticClass: "review",
                          },
                          [
                            _c("div", { staticClass: "review__icons" }, [
                              _c("img", {
                                staticClass: "review__avatar",
                                attrs: { src: value.avatar },
                              }),
                              _c("span", { staticClass: "review__rating" }, [
                                _vm._v(_vm._s(value.rating) + "/5"),
                              ]),
                            ]),
                            _c("div", { staticClass: "review__content" }, [
                              _c("div", { staticClass: "small" }, [
                                _c("b", [_vm._v(_vm._s(value.user))]),
                                _vm._v(
                                  " " +
                                    _vm._s(value.data) +
                                    "\n                                "
                                ),
                                _c("br"),
                                _c("i", [
                                  _vm._v(_vm._s(value.verification_source)),
                                ]),
                              ]),
                              _c("h5", { staticClass: "review__title" }, [
                                _vm._v(_vm._s(value.title)),
                              ]),
                              _c("p", { staticClass: "review__text" }, [
                                _vm._v(_vm._s(value.text)),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "b-modal",
                          {
                            attrs: {
                              id: "review-" + _vm.size + "-" + index,
                              "hide-footer": "",
                              title: value.title,
                            },
                            on: { change: _vm.reviewsModalOpened },
                          },
                          [
                            _c(
                              "b-container",
                              {
                                staticClass: "mt-4 mb-4",
                                attrs: { fluid: "" },
                              },
                              [
                                _c("div", { staticClass: "small" }, [
                                  _c("b", [_vm._v(_vm._s(value.user))]),
                                  _vm._v(
                                    " - " +
                                      _vm._s(value.data) +
                                      "\n                                "
                                  ),
                                  _c("br"),
                                  _c("i", [
                                    _vm._v(_vm._s(value.verification_source)),
                                  ]),
                                  _c("br"),
                                  _c("br"),
                                ]),
                                _c("p", [_vm._v(_vm._s(value.text))]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "reviews__dots" }, [
                  _c(
                    "span",
                    {
                      staticClass: "slick-arrow",
                      attrs: {
                        variant: "light",
                        id: _vm.size + "-slick-prev-review",
                      },
                    },
                    [_c("SvgChevronLeft")],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "slick-arrow",
                      attrs: {
                        variant: "light",
                        id: _vm.size + "-slick-next-review",
                      },
                    },
                    [_c("SvgChevronRight")],
                    1
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm.reviews && _vm.reviews.length == 0
        ? _c("div", [
            _c("small", [
              _vm._v("Non ci sono ancora recensioni per questa struttura."),
            ]),
            _c("br"),
            _c("br"),
          ])
        : _c("Spinner"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reviewsTitle" }, [
      _c("h5", { staticClass: "property-reviews__title" }, [
        _vm._v("Recensioni"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }