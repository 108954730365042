<template>
    <div :class="'panel-white rate default'">
        <div class="rate__left">

            <h5 class="rate__title">Soluzione {{rateIndex+1}}</h5>
            
            <hr>
            
            <div class="d-flex">
                <span class="amenity fail" v-if="rate.refundable != true" v-b-popover.hover.top="'La prenotazione non potrà essere cancellata nè modificata. Prenota quando sei sicuro del tuo viaggio'">
                    <div>
                        <SvgInfoCircle/>
                    </div>
                    Soluzione non rimborsabile
                </span>
                <span v-else class="amenity icon-refundable mb-0" v-b-popover.hover.top="'Potrai cancellare gratuitamente la prenotazione entro il ' + rate.refundableBefore + ' e il tuo voucher Salabam sarà riattivato per una nuova prenotazione'"> Cancellazione gratuita</span>

                <div class="property__prices-occupancy">
                    <span class="nowrap">
                        {{dates.nights}} <span v-if="dates.nights == 1">Notte</span><span v-else>Notti</span>
                    </span>
                    <span class="nowrap">
                        {{occupancy.adults}} <span v-if="occupancy.adults == 1">Adulto</span><span v-else>Adulti</span>
                        <span v-if="occupancy.children.length">
                        -
                        {{occupancy.children.length}} <span v-if="occupancy.children.length == 1">Bambino</span><span v-else>Bambini</span>
                        </span>
                    </span>
                </div>
            </div>

            <!--
                entro il {{stringToDate(rate.refundableBefore)}}</small>
            -->
            
            <hr>

            <div v-if="Object.values(rate.amenities).length">
                <div class="property__amenities">
                   <span v-for="(amenity) in Object.values(rate.amenitiesEps).reverse().slice(0,2)" v-bind:key="amenity.id" :class="'amenity ' + (icons['propertyAmenities'][amenity.amenity] !== undefined ? icons['propertyAmenities'][amenity.amenity] : 'icon-default')">
                      {{amenity.name}}
                   </span>

                   <span v-if="Object.values(rate.amenitiesEps).length > 2" @click="$bvModal.show('modal-room'+roomId+'-rate-'+rate.id+'-services')">
                      <BtnChevronRight :text="'Tutti i servizi'" />
                   </span>
                </div>

                <b-modal :id="'modal-room'+roomId+'-rate-'+rate.id+'-services'" size="lg" hide-footer>
                    <template v-slot:modal-title>
                        Servizi inclusi nella tariffa
                    </template>
                    <div class="modal-section">
                        <div class="property__amenities">
                           <span v-for="(amenity) in Object.values(rate.amenitiesEps).reverse()" v-bind:key="amenity.id" :class="'amenity ' + (icons['propertyAmenities'][amenity.amenity] !== undefined ? icons['propertyAmenities'][amenity.amenity] : 'icon-default')">
                                {{amenity.name}}
                            </span>
                        </div>
                    </div>
                </b-modal>

                <hr class="mt-1">
            </div>


            <div class="bedAndFees">

                <!-- SCELTA LETTO: tooltip richiesta non confermata condizionale solo se ci sono più scelte -->
                <div v-if="Object.keys(this.rate.bedding).length == 1">
                    <b-form-group>
                        <b-form-radio v-for="(bed,index) in rate.bedding" v-bind:key="index" v-model="bedKey" :name="rate.id+'-bedding-radios'" :value="index">{{bed.label}}</b-form-radio>
                    </b-form-group>
                </div>
                <div v-else v-b-popover.hover.top="'Richiesta non confermata soggetta a disponibilità'">
                    <b-form-group>
                        <b-form-radio v-for="(bed,index) in rate.bedding" v-bind:key="index" v-model="bedKey" :name="rate.id+'-bedding-radios'" :value="index">{{bed.label}}</b-form-radio>
                    </b-form-group>
                </div>
                <!-- scelta letto -->

                <div v-if="fees && mixinGetIntegrationInfo().mode == 'boxset'" :id="'popover-target-fees-'+roomId+'-'+rateIndex" class="feesBox">
                    <div class="fees">
                        <span><SvgInfoCircle/> Da pagare in struttura:</span>
                        <span class="price">{{fees}}€</span>

                        <b-popover :target="'popover-target-fees-'+roomId+'-'+rateIndex" triggers="hover" placement="top">
                            <span>Costi non inclusi nella tariffa e da pagare in struttura:</span><br/>
                            <div class="d-flex justify-content-between" v-if="rate.totals.mandatory_tax > 0">
                                <span class="pr-2">Tassa di soggiorno:</span>
                                <span>{{rate.totals.mandatory_tax*100/100}}€</span>
                            </div>
                            <div class="d-flex justify-content-between" v-if="rate.totals.mandatory_fee > 0">
                                <span class="pr-2">Spese di pulizia ed utenze:</span>
                                <span>{{rate.totals.mandatory_fee*100/100}}€</span>
                            </div>
                            <div class="d-flex justify-content-between" v-if="rate.totals.resort_fee > 0">
                                <span class="pr-2">Resort fee:</span>
                                <span>{{rate.totals.resort_fee*100/100}}€</span>
                            </div>
                        </b-popover>
                    </div>
                </div>

            </div>

            
            <hr>

        </div>

        <div class="rate__right">
            <div v-if="mixinGetIntegrationInfo().mode == 'booking'" class="room__prices">
                <PropertyPrices
                    v-on:room-selected="$parent.$emit('room-selected',{rateId: rate.id, roomId: roomId, bedKey: bedKey})"
                    :additionalInfo="{}"
                    :uniqueId="roomId+rateIndex"
                    :disableUnavailable="true"
                    :rateId="rate.id"
                    :price="rate.public_price"
                    :btnText="'Prenota'"
                    :refundable="rate.refundable"
                />
            </div>

            <div v-if="mixinGetIntegrationInfo().mode == 'boxset'" class="room__prices">
                <PropertyPrices
                    v-on:room-selected="$parent.$emit('room-selected',{rateId: rate.id, roomId: roomId, bedKey: bedKey})"
                    :additionalInfo="{}"
                    :uniqueId="roomId+rateIndex"
                    :disableUnavailable="true"
                    :rateId="rate.id"
                    :price="rate.public_price"
                    :btnText="focus + ' ' + activeBand.toUpperCase() + ' ' + nights + (nights==1 ? ' notte' : ' notti')"
                    :refundable="rate.refundable"
                />
            </div>
            
            <div v-if="fees && mixinGetIntegrationInfo().mode == 'booking'" class="feesBox">
                <br>
                <div class="fees">
                    <span><SvgInfoCircle :id="'popover-target-fees-'+roomId+'-'+rateIndex"/> Da pagare in struttura:</span>
                    <span class="price">{{fees}} €</span>

                    <b-popover :target="'popover-target-fees-'+roomId+'-'+rateIndex" triggers="hover" placement="bottom">
                        <span>Costi non inclusi nella tariffa e da pagare in struttura:</span><br/>
                        <div class="d-flex justify-content-between" v-if="rate.totals.mandatory_tax > 0">
                            <span class="pr-2">Tassa di soggiorno:</span>
                            <span>{{rate.totals.mandatory_tax*100/100}}€</span>
                        </div>
                        <div class="d-flex justify-content-between" v-if="rate.totals.mandatory_fee > 0">
                            <span class="pr-2">Spese di pulizia ed utenze:</span>
                            <span>{{rate.totals.mandatory_fee*100/100}}€</span>
                        </div>
                        <div class="d-flex justify-content-between" v-if="rate.totals.resort_fee > 0">
                            <span class="pr-2">Resort fee:</span>
                            <span>{{rate.totals.resort_fee*100/100}}€</span>
                        </div>
                    </b-popover>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import PropertyPrices from '../blocks/property/property-prices'
import BtnChevronRight from '../atoms/btn-chevron-right'
import SvgInfoCircle from './../svg/info-circle'
import { mapGetters } from 'vuex'

export default {
    name: 'rate',
    components:
    {
        BtnChevronRight,
        SvgInfoCircle,
        PropertyPrices,
    },
    props:
    {
        rate: Object,
        rateIndex: Number,
        roomId: String
    },
    computed: mapGetters({
        availability:   'getAvailability',
        dates:   'getDates',
        occupancy: 'getOccupancy',
    }),
    data() {
        return {
            amenities: {},
            icons:              Vue.prototype.$config.icons,
            amenitiesLabels:    Vue.prototype.$config.amenities,
            bedKey:             Object.keys(this.rate.bedding)[0],
            //fees:               this.rate.totals.mandatory_tax + this.rate.totals.mandatory_fee + this.rate.totals.resort_fee,
            fees: (
                parseFloat(this.rate.totals.mandatory_tax)
              + parseFloat(this.rate.totals.mandatory_fee)
              + parseFloat(this.rate.totals.resort_fee)
            ).toFixed(2),

            focus: '',
            nights: 0,
            activeBand: '',
        }
    },
    methods:
    {
        setRateInfo()
        {
            this.focus = this.$store.state.focus
            this.nights = this.$store.state.dates.nights
            this.activeBand = this.$store.state.activeBand
        },
        stringToDate(string)
        {
            return string.split(' ')[0]
        }
    },
    beforeMount(){
        this.amenities = Vue.prototype.$config.amenities
    },
    mounted()
    {
        this.setRateInfo()
    }
}
</script>



<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    .rate
    {
        ///box-shadow:$box-shadow;
        //border-radius:$border-radius;
        //background:$white;
        //padding:1rem 1rem;
        
        display:flex;
        flex-wrap:wrap;

        &:not(:last-child)
        {
            border-bottom:1px solid $gray-300;
            padding-bottom:$grid-gutter-width/2;
        }
        &:last-child
        {
            margin-bottom:0;
        }
        .bedAndFees
        {
            @include media-breakpoint-down(xs)
            {
                flex-wrap:wrap;
                .feesBox
                {
                    min-width:100%;
                    margin-top:0.5rem;
                }
            }
            display:flex;
            &>div
            {
                flex:50%;
                min-width:50%;
            }  
            .form-group
            {
                margin-bottom: 0;
            }
        }
        .rateTop
        {
            flex:1;
            min-width:100%;
            .boxsetResume
            {
                margin-top:-0.5rem;
            }
            hr
            {
                display:block;
                margin:2rem 0;
            }
        }
        .rate__left
        {
            //width:50%;
            min-width:100%;
            .custom-control-label
            {
                color:$black;
                font-size: 14px;
                padding-top:0;
            }
        }
        .rate__right
        {
            //width:50%;
            min-width:100%;
            //padding-left:$grid-gutter-width/2;
        }
        .selectBoxsetButton,
        .feesBox
        {
            max-width:200px;
            margin:auto;
        }
        .amenity,
        .property__prices-occupancy
        {
            min-width:50%;
            flex:50%;
        }
        .property__prices-occupancy
        {
            color:rgba($smooth-black,0.5);
            font-size:12px;
            display:flex;
            flex-direction:column;
            text-align:center;
        }
        .rate__title
        {
            color:$black;
            font-size:22px;
            font-weight:600;
        }
        .fail
        {
            @include media-breakpoint-up(lg)
            {
                white-space:nowrap;
            }
        }
        .property__amenities
        {
            .amenity
            {
                width:50%;
            }
        }
        .fees
        {
            color:$black;
            display:flex;
            justify-content: space-between;
            font-size: 12px;
            line-height: 120%;
            //max-width: 80%;
            margin:auto;
            align-items: center;
            cursor:pointer;
            .price
            {
                display:flex;
                align-items: center;
            }
            svg
            {
                min-width:12px;
                width:12px;
                margin-top:-1px;
                margin-right:6px;
            }
            span
            {
                display:flex;
                align-items:center;
            }
        }
        .boxsetPadProperty
        {
            padding:0.75rem 0.95rem;
            text-align:center;
            margin-bottom:1.5rem;
            font-weight: bold;
            color:$white;
        }
    }
</style>