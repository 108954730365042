<template>
    <div class="room panel-gray">
        <Spinner v-if="loading" />
        <b-row v-else>
            <b-col sm="12" lg="5" xl="6" class="room__left">
                <div class="room__left-inner">
                    <h5 class="room__name">{{room.name}}</h5>

                    <div class="room__occupancy" v-if="room.occupancy && room.occupancy.max_allowed && room.occupancy.max_allowed.total">
                        <div>
                            <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5999 7.19995C16.091 7.19995 17.2999 5.99117 17.2999 4.5C17.2999 3.00883 16.091 1.80005 14.5999 1.80005C13.1087 1.80005 11.8999 3.00883 11.8999 4.5C11.8999 5.99117 13.1087 7.19995 14.5999 7.19995Z" fill="#333333"/>
                            <path d="M20.6 12.6C20.4 9.99998 18.8 8 14.6 8C14 8 13.5 7.99998 13 8.09998C14.2 9.09998 15 10.6 15.1 12.4V12.5C15.1 12.7 15.1 12.8 15 13H20.1C20.4 13.1 20.6 12.9 20.6 12.6Z" fill="#333333"/>
                            <path d="M7.49963 6.5C9.10126 6.5 10.3997 5.2016 10.3997 3.59998C10.3997 1.99835 9.10126 0.699951 7.49963 0.699951C5.89801 0.699951 4.59961 1.99835 4.59961 3.59998C4.59961 5.2016 5.89801 6.5 7.49963 6.5Z" fill="#333333"/>
                            <path d="M0.899902 12.5C0.899902 12.8 1.0999 13 1.3999 13H13.5999C13.8999 13 14.0999 12.8 14.0999 12.5C13.8999 9.7 12.0999 7.5 7.49988 7.5C2.89988 7.5 1.0999 9.7 0.899902 12.5Z" fill="#333333"/>
                            </svg>
                            Per {{room.occupancy.max_allowed.total}} <span v-if="room.occupancy.max_allowed.total == 1">persona</span><span v-else>persone</span>
                        </div>

                        <span class="room__modal-link" @click="$bvModal.show('modal-room-'+room.id+'-features')">
                            <BtnChevronRight :text="'Caratteristiche della camera'" />
                        </span>

                        <b-modal :id="'modal-room-'+room.id+'-features'" size="lg" hide-footer>
                            <template v-slot:modal-title>
                                Caratteristiche della camera
                            </template>
                            <div class="modal-section">
                                <p v-if="room.descriptions" v-html="room.descriptions.overview"></p>
                                <div class="property__amenities">
                                    <span v-for="(at,index) in room.amenities" v-bind:key="index" :class="'amenity '+(icons.amenities[at.id] !== undefined ? icons.amenities[at.id]  : 'icon-default')">
                                        {{at.name}}
                                    </span>
                                </div>
                            </div>
                        </b-modal>
                    </div>

                    <div class="slick-container">
                        <Slick :ref="'roomSlider-'+room.id" :id="'roomSlider-'+room.id" :options="slickOptions">
                            <div v-for="(thumb,thumbIndex) in (room.images).slice(0,100)" v-bind:key="thumbIndex">
                                <div class="room__image" :style="'background-image:url('+thumb.src+')'" @click="toggleInternalModal('roomGalleryFullwidth-'+room.id,true,thumbIndex)">
                                    <span class="slick-caption">{{thumb.caption}}</span>
                                </div>
                            </div>
                        </Slick>
                        <span variant="light" class="slick-arrow slick-prev" :id="'slick-prev-'+room.id"><SvgChevronLeft /></span>
                        <span variant="light" class="slick-arrow slick-next" :id="'slick-next-'+room.id"><SvgChevronRight /></span>
                    </div>

                    <b-modal :id="'roomGalleryFullwidth-'+room.id" data-class="internalGallery" :ref="'roomGalleryFullwidth-'+room.id" size="xl" hide-header hide-footer>
                        <div class="d-block text-center slick-container">
                            <Slick
                                :options="{
                                    dots:true,
                                    fade:true,
                                    prevArrow: '#slick-prev-internal-'+room.id,
                                    nextArrow: '#slick-next-internal-'+room.id,
                                    asNavFor: '#roomSlider-'+room.id,
                                    initialSlide: firstSlideIndex
                                }"
                            >
                                <div v-for="(thumb,thumbModalIndex) in (room.images).slice(0,100)" v-bind:key="thumbModalIndex" class="internalImage">
                                    <div class="slick-caption"><span>{{thumb.caption}}</span></div>
                                    <img :src="thumb.src">
                                </div>
                            </slick>
                            <span variant="light" class="slick-arrow slick-prev" :id="'slick-prev-internal-'+room.id"><SvgChevronLeft /></span>
                            <span variant="light" class="slick-arrow slick-next" :id="'slick-next-internal-'+room.id"><SvgChevronRight /></span>
                        </div>
                        <div class="closeInternal" @click="toggleInternalModal('roomGalleryFullwidth-'+room.id,false,thumbModalIndex)">
                            <SvgCross />
                        </div>
                    </b-modal>

                    <div class="room__bedding-alert" v-if="(occupancy.adults + occupancy.children.length) > Object.values(room.rates[0].bedding)[0].paxCount">
                        <div @click="$bvModal.show('modal-room-'+room.id+'-features')">
                            <b-alert variant="warning" show class="bedding-alert">
                                <SvgInfoCircle v-b-popover.hover.top="'Il numero di posti letto di questa camera potrebbe essere inferiore al numero di viaggiatori e potrebbe essere richiesto un supplemento'"/>
                                Controlla il numero dei letti disponibili
                            </b-alert>
                        </div>

                        <div @click="$bvModal.show('modal-room-'+room.id+'-details')">
                            <b-alert variant="warning" show class="bedding-alert">
                                <SvgInfoCircle v-b-popover.hover.top="'La struttura potrebbe richiedere un supplemento per eventuali letti aggiuntivi necessari. Contattaci ad '+site.emailSupport+' o scegli un\'altra camera'"/>
                                Verifica il costo per eventuali letti aggiuntivi
                            </b-alert>
                        </div>

                        <b-modal :id="'modal-room-'+room.id+'-details'" size="lg" hide-footer>
                            <template v-slot:modal-title>
                                Informazioni importanti
                            </template>
                            <div class="modal-section">
                                <PropertyImportantInfo v-if="propertyDetails.specialDetails" :specialDetails="propertyDetails.specialDetails" />
                                <br>
                                <PropertyToKnow :details="propertyDetails" />
                            </div>
                        </b-modal>
                    </div>

                    <!--
                    <div v-if="mixinGetIntegrationInfo().mode == 'boxset'" class="boxsetPadProperty">
                        <SvgBoxsetIcon />
                        {{activeBand | capitalize}}
                        {{focus | capitalize}}
                        <span>{{nights}} </span><span v-if="nights==1">notte </span><span v-else>notti </span>
                        - {{this.$config.boxsetsConfig[focus][activeBand][nights].public_price}}€
                        <br>
                        <small>
                            Puoi prenotare questa camera con il Voucher 
                            {{activeBand | capitalize}}
                            {{focus | capitalize}}
                            <span>{{nights}} </span><span v-if="nights==1">notte </span><span v-else>notti </span>
                        </small>
                    </div>
                    -->

                    <!--
                    <div v-if="mixinGetIntegrationInfo().mode == 'boxset'">
                        <br>
                        <BoxsetResume
                            :focus="focus"
                            :band="activeBand"
                            :nights="nights"
                            :price="this.$config.boxsetsConfig[focus][activeBand][nights].public_price"
                        />
                    </div>
                    -->
                </div>
            </b-col>

            <b-col sm="12" lg="7" xl="6" class="room__right">
                <div class="room__right-inner">
                    <RoomRate v-for="(rate,index) in room.rates" v-bind:key="index" :rate="rate" :rateIndex="index" :roomId="room.id" />
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vue from 'vue'
import Spinner from '../atoms/spinner'
import BtnChevronRight from '../atoms/btn-chevron-right'
import SvgInfoCircle from './../svg/info-circle'
//import SvgBoxsetIcon from './../svg/boxset-icon'
import RoomRate from '../blocks/room-rate'
//import BoxsetResume from '../blocks/boxset/boxset-resume'
import PropertyToKnow from '../blocks/property/property-toKnow'
import PropertyImportantInfo from '../blocks/property/property-important-info'
import Slick from 'vue-slick'
import SvgChevronRight from './../svg/chevron-right'
import SvgChevronLeft from './../svg/chevron-left'
import SvgCross from './../svg/cross'
import { mapGetters } from 'vuex'

export default {
    name: 'room',
    components:
    {
        Spinner, BtnChevronRight, SvgInfoCircle, //SvgBoxsetIcon,
        RoomRate,
        //BoxsetResume,
        Slick,
        PropertyToKnow,
        PropertyImportantInfo,
        SvgCross,
        SvgChevronLeft,
        SvgChevronRight,
    },
    props: {
        room: Object,
        propertyDetails: Object,
    },
    computed: mapGetters({
        occupancy:   'getOccupancy',
        availability:   'getAvailability',
        //nights:   'getNights',
        //focus:   'getFocus',
        //activeBand:   'getActiveBand',
        bookingData:   'getBookingData',
    }),
    /*
    computed:
    {
        occupancy: {
            get() { return this.$store.state.occupancy },
            set(value) { this.mixinSendMutation('setOccupancy',value) }
        }
    },
    */
    data(){
        return {
            loading: true,
            thumbModalIndex: 0,
            firstSlideIndex: 0,
            site:               Vue.prototype.$config.site,   
            icons:              Vue.prototype.$config.icons,
            amenitiesLabels:    Vue.prototype.$config.amenities,
            focus: '',
            nights: 0,
            activeBand: '',
            slickOptions: {
                    //vertical: true,
                    //verticalSwiping: true,
                    //slidesToShow: 1,
                    //slidesToScroll: 1,
                    //infinite: true,
                    dots:true,
                    fade:true,
                    prevArrow: '#slick-prev-'+this.room.id,
                    nextArrow: '#slick-next-'+this.room.id,
                    /*
                    adaptiveHeight:true,
                    responsive: [{
                        breakpoint: 993,
                        settings: {
                            adaptiveHeight:false,
                            centerMode: true,
                            //slidesToShow: 3,
                            variableWidth: true,
                            vertical: false,
                            verticalSwiping: false,
                        }
                    }]*/
                },          
        }
    },
    mounted()
    {
        this.setRoomInfo()
        this.loading = false
    },
    methods:
    {
        setRoomInfo()
        {
            this.focus = this.$store.state.focus
            this.nights = this.$store.state.dates.nights
            this.activeBand = this.$store.state.activeBand
        },
        // replicato in search-property
        toggleInternalModal(refModal, open, start)
        {
            let _this = this
            _this.firstSlideIndex = start
            if(open)
            {
                _this.$refs[refModal].show()
            }
            else
            {
                _this.$refs[refModal].hide()
            }
        },
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    .room
    {
        //box-shadow:$box-shadow;
        //border-radius:$border-radius;
        //background:$light-gray;
        //overflow: hidden;
        //margin-bottom:$grid-gutter-width/2;

        .room__bedding-alert
        {
            cursor:pointer;
            margin-top:$grid-gutter-width/2;
            margin-bottom:$grid-gutter-width/2;
            .bedding-alert
            {
                font-size: 14px;
            }
            svg
            {
                width:16px;
                opacity:0.75;
                margin-top:-3px;
                margin-right:4px;
            }
        }

        .room__left
        {
            //padding:$grid-gutter-width/2;
            //padding-right:0;
            @include media-breakpoint-up(xl)
            {
                //padding:$grid-gutter-width/2;
                //padding-right:0;
            }
        }
        .room__right
        {
            //padding:$grid-gutter-width/2;
            @include media-breakpoint-up(xl)
            {
                //padding:$grid-gutter-width/2;
            }
        }

        .room__name
        {
            color:$black;
            font-weight:600;
            font-size:20px;
            margin-bottom:1rem;
            @include media-breakpoint-up(xl)
            {
                font-size: 25px;            
            }
        }
        .room__occupancy
        {
            display:flex;
            flex-direction:column;
            font-size:15px;
            margin-bottom:2rem;
            svg
            {
                margin-right:8px;
            }
        }

        .room__modal-link
        {
            margin-top:15px;
        }

        .room__image
        {
            width:100%;
            //height:100%;
            min-height:260px;
            background-size:cover;
            background-position:center center;
            @include media-breakpoint-up(md)
            {
                min-height:340px;
            }
        }

        @include media-breakpoint-up(lg)
        {
            .room__right
            {
                //border-left:$dashed-border;
            }
        }

        @include media-breakpoint-down(lg)
        {
            .room__right .rate
            {
                flex-wrap: wrap;
                button
                {
                    margin-bottom:1rem;
                }
                .custom-control-label
                {
                    font-size: 13px;
                }
                hr
                {
                    margin-top:1rem;
                    margin-bottom:1rem;
                }
                .rate__left,
                .rate__right
                {
                    width:100%;
                }

                .rate__right
                {
                    margin-top:1rem;
                    padding-left:0;
                }
            }
        }

        @include media-breakpoint-down(md)
        {
            .room__left
            {
                //padding-left:0;
                //padding-top:20px;
                //padding-bottom:0;
            }
            .room__right
            {
                //padding:10px 20px 20px 20px;
            }
            .room__bedding-alert
            {
                padding:0 20px;
                margin:20px 0 0 0;
            }
            .room__occupancy
            {
                padding-top:0;
                br
                {
                    display: none;
                }
            }

            .room__name
            {
                font-size:18px;
            }

            .room__modal-link
            {
                margin-top:6px;
                .btn-chevron-right
                {
                    font-size:13px;
                    svg
                    {
                        width:6 px;
                    }
                }
            }

            .room__name,
            .room__occupancy
            {
                padding-left:20px;
                padding-right:20px;
                margin-bottom:20px;
            }
        }

        .room__left-inner,
        .room__right-inner
        {
            padding-left:0;
            padding-right:0;
            //padding:$grid-gutter-width/2;

            @include media-breakpoint-down(sm)
            {
                .panel-white
                {
                    box-shadow: none;
                    background:transparent;
                    padding:0;
                }
            }
        }

        @include media-breakpoint-up(lg)
        {
            .room__left
            {
                padding-right:0;
                border-right:1px solid $gray-300;
            }
            .room__left-inner
            {
                margin-right:1.5*$grid-gutter-width;
            }
        }

        .roomDivider
        {
            display:none;
            @include media-breakpoint-up(lg)
            {
                display:flex;
                position:relative;
                &:after
                {
                    content:"";
                    height: 100%;
                    right:0;
                    width:1px;
                    background:$gray-300;
                    position:absolute;
                }
            }
        }

        .boxsetPadProperty
        {
            font-weight: bold;
            //text-align:center;
            padding:0.75rem 1rem;
            margin-top:1rem;
            color:$white;
            svg
            {
                width:26px;
                transform:translateY(-2px);
                *
                {
                    fill:$white;
                }
            }

            position:relative;
            padding-left:2.5rem;
            @include media-breakpoint-down(md)
            {
                text-align: left;
                font-size: 14px;
            }
            svg
            {
                position:absolute;
                top:0px;
                left:10px;
                width:20px;
            }
        }

        @include media-breakpoint-down(xs)
        {
            &.panel-gray
            {
                padding:$grid-gutter-width/3 $grid-gutter-width/3;
            }
            .room__name,
            .room__occupancy
            {
                padding-left:0;
                padding-right:0;
            }
        }        
    }
</style>