<template>
    <div class="property-important-info">
        <ul v-for="(value,index) in specialDetails" v-bind:key="'property-specialDetails-'+index">
            <li v-html="value" class="property-detail-content"></li>
        </ul>
        <br />
        <p>
            In caso di problemi al checkin o durante il soggiorno ti preghiamo di chiedere 
            immediatamente supporto alla reception/gestore della struttura e se necessario 
            all'assistenza Salabam all'indirizzo email {{emailSupport}} 
            o al numero di telefono {{phoneNumber}}. 
            Non potremo fornirti alcun supporto se decidessi di non soggiornare 
            nella struttura prenotata o dovessi prenotare un'altra 
            soluzione autonomamente.
        </p>
    </div>
</template>

<script>
import Vue from 'vue'
export default {
    name: 'property-important-info',
    props:
    {
        specialDetails: Array,
    },
    data() {
        return {
            emailSupport:    Vue.prototype.$config.site.emailSupport,
            phoneNumber:    Vue.prototype.$config.site.phoneNumber,
        }
    }
}
</script>

<style lang="scss">
    //@import "~bootstrap/scss/functions";
    //@import './src/scss/_custom.scss';
</style>